import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../assets/imgs/right.svg'


const _hoisted_1 = {
  key: 0,
  class: "flex"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0
}
const _hoisted_4 = { class: "detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock("div", {
    class: ["card", `${_ctx.rounded ? 'card-rounded' : ''}`],
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.link(_ctx.learnmore)))
  }, [
    _createVNode("div", {
      class: "card__content",
      style: !_ctx.learnmore ? { alignItems: 'flex-start' } : ''
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock("div", _hoisted_1, [
            _createVNode(_component_Icon, {
              name: _ctx.icon,
              background: _ctx.color,
              color: _ctx.iconColor,
              scale: _ctx.iconScale
            }, null, 8, ["name", "background", "color", "scale"])
          ]))
        : _createCommentVNode("", true),
      _createVNode("div", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
        (_ctx.learnmore)
          ? (_openBlock(), _createBlock("img", _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createVNode("div", _hoisted_4, _toDisplayString(_ctx.detail), 1)
    ], 4),
    (_ctx.learnmore)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          text: _ctx.text.learnmore,
          class: "learnmore hide-mobile",
          btnType: "link",
          size: "sm"
        }, null, 8, ["text"]))
      : _createCommentVNode("", true)
  ], 2))
}