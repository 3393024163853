import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "signup" }
const _hoisted_2 = { class: "signup__content" }
const _hoisted_3 = { class: "signup__content--benefits hide-mobile" }
const _hoisted_4 = { class: "signup__form" }
const _hoisted_5 = { class: "signup__form--title" }
const _hoisted_6 = { class: "form" }
const _hoisted_7 = { class: "signup__content--benefits hide-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!
  const _component_SalesValue = _resolveComponent("SalesValue")!

  return (_openBlock(), _createBlock("div", {
    id: "signup",
    class: _ctx.currentRouteSignup
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", {
          class: "signup__content--title",
          innerHTML: _ctx.currentText.title,
          "data-aos": "fade-up"
        }, null, 8, ["innerHTML"]),
        _createVNode("div", {
          class: "signup__content--titlehighlight",
          innerHTML: _ctx.currentText.titlehighlight,
          "data-aos": "fade-up"
        }, null, 8, ["innerHTML"]),
        _createVNode("div", {
          class: "signup__content--detail",
          innerHTML: _ctx.currentText.detail,
          "data-aos": "fade-up"
        }, null, 8, ["innerHTML"]),
        _createVNode("div", _hoisted_3, [
          _createVNode("div", {
            class: "signup__content--benefits-title",
            innerHTML: _ctx.mainText.benefits
          }, null, 8, ["innerHTML"]),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.currentText.benefits, (benefits) => {
            return (_openBlock(), _createBlock("div", {
              class: "signup__content--benefits-list",
              key: benefits,
              innerHTML: benefits
            }, null, 8, ["innerHTML"]))
          }), 128))
        ])
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, [
          _createVNode("span", {
            innerHTML: _ctx.text.form_title
          }, null, 8, ["innerHTML"])
        ]),
        _createVNode("div", _hoisted_6, [
          _createVNode(_component_Form)
        ])
      ]),
      _createVNode("div", _hoisted_7, [
        _createVNode("div", {
          class: "signup__content--benefits-title",
          innerHTML: _ctx.mainText.benefits
        }, null, 8, ["innerHTML"]),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.text.benefits, (benefits) => {
          return (_openBlock(), _createBlock("div", {
            class: "signup__content--benefits-list",
            key: benefits,
            innerHTML: benefits
          }, null, 8, ["innerHTML"]))
        }), 128))
      ])
    ]),
    _createVNode(_component_SalesValue)
  ], 2))
}