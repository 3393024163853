
import { Options, Vue } from "vue-class-component";
import Icon from "../components/Icon.vue";

@Options({
  name: "customInput",
  components: { Icon },
  props: {
    name: String,
    type: String,
    icon: String,
    error: String,
    placeholder: {
      type: String,
      default: "",
    },
    defaultValue: String,
    radioOption: [],
    onChangeMethod: Function,
  },
  methods: {
    onChange(event: any) {
      if (this.onChangeMethod) {
        this.onChangeMethod(event);
      }
    },
  },
})
export default class CustomInput extends Vue {
  name?: string;
  type?: string;
  icon?: string;
  placeholder?: string;
  defaultValue?: string;
  radioOption?: any;
  onChange?: any;
  onChangeMethod?: any;
  model?: any;
  error?: any;
}
