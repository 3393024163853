
import { Options, Vue } from "vue-class-component";
import "../assets/scss/select_for_signup.scss";
import { TEXT } from "../text";
import Button from "../components/Button.vue";
import SalesValue from "../components/SalesValue.vue";

@Options({
  name: "select_for_signup",
  components: { Button, SalesValue },
  props: {
    title: String,
  },
  data() {
    return {
      mainText: TEXT,
      text: TEXT.select_for_signup,
    };
  },
})
export default class SelectForSignup extends Vue {
  title?: string;
  mainText?: any;
  text?: any;
}
