
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    title: String,
    border: Boolean,
    color: {
      type: String,
      default: "rgba(44, 50, 73, 0.7)",
    },
    imgSize: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      styleImg: {
        width: this.imgSize + "px",
        height: this.imgSize + "px",
        border: this.border ? "1px solid #D3D3D3" : "none",
      },
      style: {
        color: this.color,
      },
    };
  },
})
export default class TrustedBy extends Vue {
  title!: string;
  style?: any;
  styleImg?: any;
}
