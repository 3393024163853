
import { Options, Vue } from "vue-class-component";
import "../assets/scss/footer.scss";
import { TEXT } from "../text";
import { Menus } from "../types/menu";

@Options({
  name: "footer",
  components: {},
  props: {
    menus: [],
  },
  data() {
    return {
      text: TEXT,
    };
  },
  methods: {
    isExternal(to: string) {
      return typeof to === "string" && to.startsWith("http");
    },
    linktohome() {
      window.scrollTo(0, 0);
      this.$router.push("/");
    },
  },
})
export default class Footer extends Vue {
  text?: any;
  menus!: Menus[];
  isExternal?: any;
  linktohome?: any;
}
