import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { id: "select_for_signup" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "content_card" }
const _hoisted_4 = { class: "content_card__text" }
const _hoisted_5 = { class: "content_card" }
const _hoisted_6 = { class: "content_card__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_SalesValue = _resolveComponent("SalesValue")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", {
        class: "title",
        innerHTML: _ctx.title ? _ctx.title : _ctx.text.title,
        "data-aos": "fade-up",
        "data-aos-anchor-placement": "top-bottom"
      }, null, 8, ["innerHTML"]),
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("div", {
            class: "content_card__text--title",
            innerHTML: _ctx.mainText.developer
          }, null, 8, ["innerHTML"]),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.mainText.signuppage.developer.benefits, (benefits) => {
            return (_openBlock(), _createBlock("div", {
              class: "content_card__text--benefits developer",
              key: benefits,
              innerHTML: benefits
            }, null, 8, ["innerHTML"]))
          }), 128)),
          _createVNode(_component_Button, {
            text: _ctx.mainText.developerRegister,
            to: "/signup?signup=developer",
            class: "content_card__btn",
            fullWidth: "",
            outline: ""
          }, null, 8, ["text"])
        ])
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode("div", _hoisted_6, [
          _createVNode("div", {
            class: "content_card__text--title",
            innerHTML: _ctx.mainText.agent
          }, null, 8, ["innerHTML"]),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.mainText.signuppage.agent.benefits, (benefits) => {
            return (_openBlock(), _createBlock("div", {
              class: "content_card__text--benefits agent",
              key: benefits,
              innerHTML: benefits
            }, null, 8, ["innerHTML"]))
          }), 128)),
          _createVNode(_component_Button, {
            text: _ctx.mainText.register,
            to: "/signup?signup=agent",
            class: "content_card__btn",
            fullWidth: "",
            outline: ""
          }, null, 8, ["text"])
        ])
      ])
    ]),
    _createVNode(_component_SalesValue)
  ]))
}