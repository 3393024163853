
import { Options, Vue } from "vue-class-component";
import CustomInput from "../components/CustomInput.vue";
import Button from "../components/Button.vue";
import Loading from "../components/Loading.vue";
import { TEXT } from "../text";
import axios from "axios";

@Options({
  name: "Form",
  components: { CustomInput, Button, Loading },
  data() {
    return {
      mainText: TEXT,
      formText: TEXT.form,
      errors: [],
      fullName: null,
      company: null,
      telphone: null,
      email: null,
      password: null,
      errorMess: null,
    };
  },
  prop: {
    loading: Boolean,
    type: String,
  },
  computed: {
    currentRouteDemo() {
      return this.$route.name === "Request Demo";
    },
    currentRouteSignup() {
      return this.$route.query.signup;
    },
  },
  methods: {
    typeChange(event: any) {
      window.scrollTo(0, 0);
      this.$router.push(
        `/signup?signup=${event.target.value.toLowerCase().trim()}`
      );
    },
    submitForm: async function (e: any) {
      e.preventDefault();
      if (this.checkForm()) {
        // console.log("signup");
        if (this.$route.query.signup === "agent") {
          this.loading = true;
          try {
            const signup = await axios.post("https://api.propfit.asia/", {
              operationName: "createUser",
              query: `mutation createUser($email: String!, $company: String, $password: String!, $name: String!, $mobile: String, $avatar: String, $team: String) {
                    createUser(data: {email: $email, company: $company, password: $password, name: $name, mobile: $mobile, avatar: $avatar, team: $team}) {
                      token
                      __typename
                  }
                }`,
              variables: {
                avatar: "",
                company: this.getValue(this.company) || "",
                email: this.getValue(this.email),
                mobile: this.getValue(this.telphone),
                name: this.getValue(this.fullName),
                password: this.getValue(this.password),
              },
            });
            // console.log("signup", signup);
            this.errorMess = null;
            if (signup?.data?.data?.createUser) {
              // succsess
              const token = signup?.data?.data?.createUser.token;
              localStorage.setItem("token", token);
              localStorage.setItem("auth._token.local", token);
              localStorage.setItem("auth.strategy", "local");
              document.cookie +=
                "auth.strategy=local;auth._token.local=" + token + ";";
              window.location.href =
                "https://propfit.asia/verify?from=/signup";
            } else {
              throw signup?.data?.errors
                .map((e: any) => {
                  return e.message;
                })
                .join(", ");
            }
          } catch (error) {
            this.loading = false;
            // error
            console.error("signup error :", error);
            this.errorMess = error;
          }
          this.loading = false;
        } else if (
          this.$route.query.signup === "developer" ||
          this.currentRouteDemo
        ) {
          this.loading = true;
          try {
            const signup = await axios.post(
              "https://propfit-app-api.herokuapp.com/register",
              {
                company: this.getValue(this.company),
                email: this.getValue(this.email),
                phone: this.getValue(this.telphone),
                name: this.getValue(this.fullName),
              }
            );
            if (signup?.data?.data?.success) {
              // window.location.reload();
              window.scrollTo(0, 0);
              this.$router.push("/signupsuccess");
              console.log("developer signup success");
            } else {
              throw "Signup Error";
            }
            this.loading = false;
          } catch (error) {
            // error
            console.error("signup error :", error);
            this.errorMess = error;
          }
          this.loading = false;
        }
      } else {
        console.error("error", this.errors);
        return false;
      }
    },
    checkForm: function () {
      this.errors = {
        fullName: null,
        company: null,
        email: null,
        telphone: null,
        password: null,
      };

      // fullName
      if (!this.fullName || !this.getValue(this.fullName)) {
        this.errors.fullName = "Name required.";
      }

      // company
      if (this.$route.query.signup === "developer" || this.currentRouteDemo) {
        if (!this.company) {
          this.errors.company = "Company required.";
        }
      }

      // email
      if (!this.email) {
        this.errors.email = "Email required.";
      } else if (!this.validEmail(this.getValue(this.email))) {
        this.errors.email = "Valid email required.";
      }

      // telphone
      if (!this.telphone) {
        this.errors.telphone = "Telphone required.";
      } else if (!this.validTel(this.getValue(this.telphone))) {
        this.errors.telphone = "Valid telphone required.";
      }

      // password
      if (this.$route.query.signup === "agent") {
        if (!this.password) {
          this.errors.password = "Password required.";
        } else if (!this.validPassword(this.getValue(this.password))) {
          this.errors.password = "Valid password required.";
        }
      }
      const checkError = [];
      for (let key of Object.keys(this.errors)) {
        let error = this.errors[key];
        if (error) {
          checkError.push(error);
        }
      }

      if (!checkError.length) {
        return true;
      }
    },
    getValue: function (ele: any) {
      if (ele) {
        return ele.target.value;
      } else {
        return null;
      }
    },
    validEmail: function (mail: string) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(mail);
    },
    validTel: function (tel: string) {
      var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
      return tel.match(phoneno);
    },
    validPassword: function (pass: string) {
      var passw = /^[A-Za-z]\w{7,14}$/;
      return pass.match(passw);
    },
  },
})
export default class Form extends Vue {
  mainText?: any;
  formText?: any;
  currentRouteDemo?: any;
  currentRouteSignup?: any;
  checkForm?: any;
  type?: string;
  fullName?: string;
  company?: string;
  telphone?: string;
  email?: string;
  password?: string;
  errors?: any;
  typeChange?: any;
  submitForm?: any;
  errorMess?: string;
  loading?: boolean;
}
