
import { Options, Vue } from "vue-class-component";
import { TEXT } from "../text";
import Button from "../components/Button.vue"; // @ is an alias to /src
import Icon from "../components/Icon.vue"; // @ is an alias to /src
import { useRoute } from "vue-router";
const route = useRoute();
@Options({
  props: {
    title: String,
    detail: String,
    learnmore: String,
    icon: String,
    color: {
      type: String,
      default: "#E4EEFF",
    },
    iconColor: String,
    iconScale: Boolean,
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  components: { Button, Icon },
  data() {
    return {
      text: TEXT,
      styleIconBG: {
        backgroundColor: this.color,
      },
    };
  },
  methods: {
    link(path: string) {
      if (path) {
        window.scrollTo(0, 0);
        this.$router.push(path);
      }
    },
  },
})
export default class Card extends Vue {
  text?: any;
  title!: string;
  detail?: string;
  learnmore?: string;
  icon?: string;
  color?: string;
  styleIconBG?: any;
  link?: any;
  iconColor?: string;
  iconScale?: boolean;
  rounded?: boolean;
}
