import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: `block block-${_ctx.rounded} ${_ctx.scale ? 'block-scale':''} ${_ctx.skiny ? 'block-skiny':''}`,
    style: _ctx.styleBlock
  }, [
    _createVNode("div", {
      class: `icon icon-${_ctx.size}`,
      style: _ctx.style
    }, null, 6)
  ], 6))
}