
import { Options, Vue } from "vue-class-component";

enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
  Link = "link",
  Opacity = "opacity",
  // Outline = "outline",
  // success = "success",
  // info = "info",
  // warning = "warning",
  // danger = "danger",
}

enum ButtonSize {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

enum IconAlign {
  Left = "left",
  Right = "right",
}

@Options({
  props: {
    text: String,
    btnType: ButtonType,
    outline: Boolean,
    active: Boolean,
    disable: Boolean,
    fullWidth: Boolean,
    onClick: Function,
    to: String,
    size: ButtonSize,
    icon: {
      type: Boolean,
      default: true,
    },
    iconAlign: IconAlign,
  },
  // computed: {

  // },
  methods: {
    linkto(path: string) {
      if (path) {
        if (this.validURL(path)) {
          window.open(path, "_blank");
          // window.location.href = path;
        } else if (path.indexOf("login") >= 0) {
          window.location.href = "/login";
        } else {
          window.scrollTo(0, 0);
          this.$router.push(path);
        }
      }
    },
    validURL(str: string) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
  },
})
export default class Button extends Vue {
  text!: string;
  to!: string;
  icon?: boolean;
  iconAlign?: IconAlign;
  btnType?: ButtonType;
  size?: ButtonSize;
  fullWidth?: boolean;
  outline?: boolean;
  linkto?: any;
}
