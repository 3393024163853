import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "trusted__title" }
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    id: "trusted",
    style: _ctx.style
  }, [
    _createVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", {
        class: "trusted__img sansiri",
        style: _ctx.styleImg
      }, null, 4),
      _createVNode("div", {
        class: "trusted__img ap",
        style: _ctx.styleImg
      }, null, 4),
      _createVNode("div", {
        class: "trusted__img a5",
        style: _ctx.styleImg
      }, null, 4)
    ])
  ], 4))
}