
import { Options, Vue } from "vue-class-component";
import "../assets/scss/sales_value.scss";
import { TEXT } from "../text";

@Options({
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    hideIconOnMobile: Boolean,
  },
  data() {
    return {
      text: TEXT,
    };
  },
})
export default class SalesValue extends Vue {
  text?: any;
  showIcon!: boolean;
  hideIconOnMobile!: boolean;
}
