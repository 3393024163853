import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "request_demo" }
const _hoisted_2 = { id: "banner" }
const _hoisted_3 = { class: "banner" }
const _hoisted_4 = { class: "banner__content" }
const _hoisted_5 = { class: "banner__form" }
const _hoisted_6 = { class: "form" }
const _hoisted_7 = { id: "service" }
const _hoisted_8 = { class: "service" }
const _hoisted_9 = { class: "service__content" }
const _hoisted_10 = { class: "service__cta" }
const _hoisted_11 = { id: "comments" }
const _hoisted_12 = { class: "comments" }
const _hoisted_13 = { class: "comments__content" }
const _hoisted_14 = { class: "comments__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TrustedBy = _resolveComponent("TrustedBy")!
  const _component_Form = _resolveComponent("Form")!
  const _component_SalesValue = _resolveComponent("SalesValue")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Button = _resolveComponent("Button")!
  const _component_CardComment = _resolveComponent("CardComment")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("section", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("div", {
            class: "banner__content--title",
            innerHTML: _ctx.text.banner.title,
            "data-aos": "fade-up"
          }, null, 8, ["innerHTML"]),
          _createVNode("div", {
            class: "banner__content--detail",
            innerHTML: _ctx.text.banner.detail
          }, null, 8, ["innerHTML"]),
          _createVNode(_component_TrustedBy, {
            title: _ctx.mainText.trustedBy,
            imgSize: "60",
            color: "#fff",
            class: "hide-mobile"
          }, null, 8, ["title"])
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode("div", {
            class: "banner__form--title",
            innerHTML: _ctx.text.form.title
          }, null, 8, ["innerHTML"]),
          _createVNode("div", _hoisted_6, [
            _createVNode(_component_Form)
          ])
        ]),
        _createVNode(_component_TrustedBy, {
          title: _ctx.mainText.trustedBy,
          imgSize: "60",
          color: "#fff",
          class: "hide-md"
        }, null, 8, ["title"])
      ])
    ]),
    _createVNode(_component_SalesValue),
    _createVNode("section", _hoisted_7, [
      _createVNode("div", _hoisted_8, [
        _createVNode("div", {
          class: "service__title",
          innerHTML: _ctx.text.service.title,
          "data-aos": "fade-up"
        }, null, 8, ["innerHTML"]),
        _createVNode("div", _hoisted_9, [
          _createVNode(_component_Card, {
            title: _ctx.text.service.stock_title,
            detail: _ctx.text.service.stock_detail,
            icon: "stock",
            color: "#E4EEFF",
            iconColor: "#3F7DF4",
            iconScale: "",
            rounded: ""
          }, null, 8, ["title", "detail"]),
          _createVNode(_component_Card, {
            title: _ctx.text.service.marketing_title,
            detail: _ctx.text.service.marketing_detail,
            icon: "best-sale",
            color: "#FFE4EC",
            iconColor: "#E27070",
            iconScale: "",
            rounded: ""
          }, null, 8, ["title", "detail"]),
          _createVNode(_component_Card, {
            title: _ctx.text.service.management_title,
            detail: _ctx.text.service.management_detail,
            icon: "operation-team",
            color: "#EBE4FF",
            iconColor: "#8460EA",
            iconScale: "",
            rounded: ""
          }, null, 8, ["title", "detail"])
        ]),
        _createVNode("div", _hoisted_10, [
          _createVNode(_component_Button, {
            text: _ctx.mainText.request_demo,
            fullWidth: "",
            size: "l",
            btnType: "opacity",
            to: "/requestdemo"
          }, null, 8, ["text"])
        ])
      ])
    ]),
    _createVNode("section", _hoisted_11, [
      _createVNode("div", _hoisted_12, [
        _createVNode("div", {
          class: "comments__title",
          innerHTML: _ctx.text.comments.title,
          "data-aos": "fade-up"
        }, null, 8, ["innerHTML"]),
        _createVNode("div", {
          class: "comments__subtitle",
          innerHTML: _ctx.text.comments.subtitle,
          "data-aos": "fade-up"
        }, null, 8, ["innerHTML"]),
        _createVNode("div", _hoisted_13, [
          _createVNode(_component_CardComment, {
            logo: "sansiri",
            text: _ctx.text.comments.sansiri,
            name: _ctx.mainText.customers.sansiri
          }, null, 8, ["text", "name"]),
          _createVNode(_component_CardComment, {
            logo: "ap",
            text: _ctx.text.comments.ap,
            name: _ctx.mainText.customers.ap
          }, null, 8, ["text", "name"]),
          _createVNode(_component_CardComment, {
            logo: "a5",
            text: _ctx.text.comments.a5,
            name: _ctx.mainText.customers.a5
          }, null, 8, ["text", "name"])
        ]),
        _createVNode("div", _hoisted_14, [
          _createVNode(_component_Button, {
            text: _ctx.mainText.request_demo,
            fullWidth: "",
            size: "l",
            to: "/requestdemo"
          }, null, 8, ["text"])
        ])
      ])
    ])
  ]))
}