
import { Options, Vue } from "vue-class-component";
import "../assets/scss/home.scss";
import { TEXT } from "../text";
import TrustedBy from "../components/TrustedBy.vue";
import SalesValue from "../components/SalesValue.vue";
import Card from "../components/Card.vue";
import CardMini from "../components/CardMini.vue";
import Button from "../components/Button.vue";
import SelectForSignup from "../views/SelectForSignup.vue";

@Options({
  name: "home",
  components: {
    TrustedBy,
    SalesValue,
    Card,
    CardMini,
    Button,
    SelectForSignup,
  },
  data() {
    return {
      text: TEXT,
    };
  },
})
export default class Home extends Vue {
  text?: any;
}
