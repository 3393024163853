
import { Options, Vue } from "vue-class-component";
import "../assets/scss/sales_value.scss";
import Icon from "../components/Icon.vue"; // @ is an alias to /src
import { TEXT } from "../text";

@Options({
  props: {
    title: String,
    detail: String,
    icon: String,
    iconAlign: {
      type: String,
      default: "right",
    },
    iconRounded: String,
    color: String,
    iconColor: String,
    mobileOutline: Boolean,
  },
  components: { Icon },
  data() {
    return {
      text: TEXT,
      styleContent: {
        order: this.iconAlign === "left" ? 1 : 0,
      },
    };
  },
})
export default class CardMini extends Vue {
  title!: string;
  detail?: string;
  icon?: string;
  color?: string;
  iconColor?: string;
  styleContent?: any;
  iconAlign?: "left" | "right";
  iconRounded?: "square" | "rounded" | "full";
  mobileOutline?: boolean;
}
