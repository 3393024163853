
import { Options, Vue } from "vue-class-component";
import "../assets/scss/header.scss";
import Icon from "../components/Icon.vue"; // @ is an alias to /src
import Button from "../components/Button.vue"; // @ is an alias to /src
import { TEXT } from "../text";
import { Menus } from "../types/menu";

@Options({
  name: "header",
  components: { Icon, Button },
  props: {
    menus: [],
  },
  data() {
    return {
      hamburger: false,
      tel: TEXT.tel,
      address: TEXT.address,
      agentLogin: TEXT.agentLogin,
      signup: TEXT.signup,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentRouteSignup() {
      return this.$route.query.signup;
    },
  },
  methods: {
    linktohome() {
      window.scrollTo(0, 0);
      this.$router.push("/");
    },
    isExternal(to: string) {
      return typeof to === "string" && to.startsWith("http");
    },
  },
})
export default class Header extends Vue {
  hamburger?: boolean;
  menus!: Menus[];
  tel?: string;
  address?: string;
  agentLogin?: string;
  signup?: string;
  currentRouteName?: any;
  currentRouteSignup?: any;
  linktohome?: any;
  isExternal?: any;
}
