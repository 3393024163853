import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../assets/imgs/star.svg'


const _hoisted_1 = { id: "sales_value" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "flex-center content__text" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        (_ctx.showIcon)
          ? (_openBlock(), _createBlock("img", {
              key: 0,
              src: _imports_0,
              class: `${_ctx.hideIconOnMobile ? 'hide-mobile' : ''}`
            }, null, 2))
          : _createCommentVNode("", true),
        _createVNode("div", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.text.sales_value_text) + " ", 1),
          _createVNode("b", null, [
            _createVNode("span", null, _toDisplayString(_ctx.text.sales_value), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.text.baht), 1)
          ])
        ])
      ]),
      _createVNode("div", _hoisted_5, _toDisplayString(_ctx.text.sales_value_date), 1)
    ])
  ]))
}