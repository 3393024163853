import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "radio"
}
const _hoisted_2 = {
  key: 1,
  class: "default"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock("div", {
    class: `customInput ${_ctx.error ? 'has-error' : ''}`
  }, [
    (_ctx.type === `radio`)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.radioOption, (radio) => {
            return (_openBlock(), _createBlock("div", {
              key: radio,
              class: "radio__item"
            }, [
              _createVNode("input", {
                type: _ctx.type,
                id: radio,
                name: _ctx.name,
                value: radio,
                checked: _ctx.defaultValue === radio.toLowerCase().trim(),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChange($event)))
              }, null, 40, ["type", "id", "name", "value", "checked"]),
              _createVNode("label", { for: radio }, _toDisplayString(radio), 9, ["for"])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock("div", _hoisted_2, [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                class: "inputicon",
                name: _ctx.icon,
                skiny: true,
                color: "#2C3249",
                size: "sm"
              }, null, 8, ["name"]))
            : _createCommentVNode("", true),
          _createVNode("input", {
            type: _ctx.type,
            placeholder: _ctx.placeholder,
            name: _ctx.name,
            onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('input', $event.target.value)))
          }, null, 40, ["type", "placeholder", "name"])
        ])),
    (_ctx.error)
      ? (_openBlock(), _createBlock("div", {
          key: 2,
          class: "error",
          innerHTML: _ctx.error
        }, null, 8, ["innerHTML"]))
      : _createCommentVNode("", true)
  ], 2))
}