import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock("div", {
    class: ["card_shadow", `${_ctx.mobileOutline ? 'card_shadow__mobileoutline' : ''}`]
  }, [
    _createVNode("div", {
      class: "content",
      style: _ctx.styleContent
    }, [
      _createVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createVNode("div", _hoisted_2, _toDisplayString(_ctx.detail), 1)
    ], 4),
    _createVNode(_component_Icon, {
      name: _ctx.icon,
      background: _ctx.color,
      color: _ctx.iconColor,
      rounded: _ctx.iconRounded,
      scale: false
    }, null, 8, ["name", "background", "color", "rounded"])
  ], 2))
}