
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    name: String,
    size: {
      type: String,
      default: 'm',
    },
    rounded: {
      type: String,
      default: 'rounded',
    },
    color: {
      type: String,
      default: '#3F7DF4',
    },
    scale: {
      type: Boolean,
      default: true,
    },
    background: {
      type: String,
      default: '#E4EEFF',
    },
    skiny: Boolean,
  },
  data() {
    return {
      styleBlock: {
        background: this.skiny ? 'transparent' : this.background,
        // padding: this.skiny ? '0' : '20px',
      },
      style:{
        backgroundColor: this.color,
        maskImage: `url(${require('@/assets/imgs/icons/'+this.name+'.svg')})`,
      }
    }
  }
})

export default class Icon extends Vue {
  name!: string;
  size?: 'xs'| 's' | 'sm' | 'm' | 'l' | 'xl';
  rounded?: 'square' | 'rounded' | 'full';
  color?: string;
  scale?: boolean;
  background?: string;
  skiny?: boolean;
  styleBlock?: any;
  style?: any;
}
