
import { Options, Vue } from "vue-class-component";
import "../assets/scss/signup_success.scss";
import { TEXT } from "../text";
import Button from "../components/Button.vue";

@Options({
  name: "signup_success",
  components: { Button },
  data() {
    return {
      text: TEXT,
    };
  },
})
export default class SignupSuccess extends Vue {
  text?: any;
}
