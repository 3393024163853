import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "card_comment" }
const _hoisted_2 = { class: "card_comment" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("img", {
        class: "card_comment--logo",
        src: require(`@/assets/imgs/${_ctx.logo}.png`)
      }, null, 8, ["src"]),
      _createVNode("div", {
        class: "card_comment--text",
        innerHTML: _ctx.text
      }, null, 8, ["innerHTML"])
    ]),
    _createVNode("div", {
      class: "card_comment--name",
      innerHTML: _ctx.name
    }, null, 8, ["innerHTML"])
  ]))
}