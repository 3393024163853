
import { Options, Vue } from "vue-class-component";
import "../assets/scss/signup.scss";
import SalesValue from "../components/SalesValue.vue";
import CustomInput from "../components/CustomInput.vue";
import Button from "../components/Button.vue";
import Loading from "../components/Loading.vue";
import Form from "../components/Form.vue";
import { TEXT } from "../text";
import axios from "axios";

@Options({
  name: "signup",
  components: { SalesValue, CustomInput, Button, Loading, Form },
  data() {
    return {
      mainText: TEXT,
      text:
        this.$route.query.signup === "developer"
          ? TEXT.signuppage.developer
          : TEXT.signuppage.agent,
      formText: TEXT.form,
      errors: [],
      type: null,
      fullName: null,
      company: null,
      telphone: null,
      email: null,
      password: null,
      errorMess: null,
    };
  },
  prop: {
    loading: Boolean,
  },

  computed: {
    currentText() {
      return this.$route.query.signup === "developer"
        ? TEXT.signuppage.developer
        : TEXT.signuppage.agent;
    },
    currentRouteSignup() {
      return this.$route.query.signup;
    },
  },
})
export default class Signup extends Vue {
  mainText?: any;
  text?: any;
  formText?: any;
  currentRouteSignup?: any;
}
