
import { Options, Vue } from "vue-class-component";
import Header from "@/components/Header.vue"; // @ is an alias to /src
import Footer from "@/components/Footer.vue"; // @ is an alias to /src
import { TEXT } from "@/text";
import { Menus } from "@/types/menu";
import AOS from "aos";
import "aos/dist/aos.css";

@Options({
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      menus: [
        { id: 1, title: TEXT.menu.home, to: "/" },
        { id: 2, title: TEXT.menu.forDeveloper, to: "/requestdemo" },
        {
          id: 3,
          title: TEXT.menu.forAgent,
          to: "/agent",
        },
        { id: 4, title: TEXT.menu.aboutus, to: "/about" },
      ],
    };
  },
  methods: {
    isMobile() {
      console.log("hello");
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    AOS.init();
  },
})
export default class App extends Vue {
  menus!: Menus[];
}
