import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 4,
  class: "already__got_account"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode("form", {
      id: "form_signup",
      onSubmit: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args))),
      action: "",
      method: "post",
      novalidate: "false"
    }, [
      (_ctx.errorMess)
        ? (_openBlock(), _createBlock("div", {
            key: 0,
            innerHTML: _ctx.errorMess,
            class: "errorMess"
          }, null, 8, ["innerHTML"]))
        : _createCommentVNode("", true),
      (!_ctx.currentRouteDemo)
        ? (_openBlock(), _createBlock(_component_CustomInput, {
            key: 1,
            type: "radio",
            radioOption: [_ctx.mainText.developer, _ctx.mainText.agent],
            defaultValue: _ctx.currentRouteSignup ? _ctx.currentRouteSignup : 'agent',
            name: "type",
            onChangeMethod: _ctx.typeChange,
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.type = $event))
          }, null, 8, ["radioOption", "defaultValue", "onChangeMethod"]))
        : _createCommentVNode("", true),
      _createVNode(_component_CustomInput, {
        type: "text",
        icon: "user",
        placeholder: _ctx.formText.fullName,
        name: "fullName",
        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fullName = $event)),
        error: _ctx.errors?.fullName || null
      }, null, 8, ["placeholder", "error"]),
      (_ctx.currentRouteSignup === 'developer' || _ctx.currentRouteDemo)
        ? (_openBlock(), _createBlock(_component_CustomInput, {
            key: 2,
            type: "text",
            icon: "building",
            placeholder: _ctx.formText.company,
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.company = $event)),
            name: "company",
            error: _ctx.errors?.company || null
          }, null, 8, ["placeholder", "error"]))
        : _createCommentVNode("", true),
      _createVNode(_component_CustomInput, {
        type: "tel",
        icon: "telphone",
        placeholder: _ctx.formText.telphone,
        onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.telphone = $event)),
        name: "telphone",
        error: _ctx.errors?.telphone || null
      }, null, 8, ["placeholder", "error"]),
      _createVNode(_component_CustomInput, {
        type: "email",
        icon: "email",
        placeholder: _ctx.formText.email,
        onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.email = $event)),
        name: "email",
        error: _ctx.errors?.email || null
      }, null, 8, ["placeholder", "error"]),
      (_ctx.currentRouteSignup != 'developer' && !_ctx.currentRouteDemo)
        ? (_openBlock(), _createBlock(_component_CustomInput, {
            key: 3,
            type: "password",
            icon: "password",
            placeholder: _ctx.formText.password,
            onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.password = $event)),
            name: "password",
            error: _ctx.errors?.password || null
          }, null, 8, ["placeholder", "error"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Button, {
        type: "submit",
        text: _ctx.currentRouteSignup == 'developer'?_ctx.mainText.developerSignupButton:_ctx.mainText.signup,
        fullWidth: "",
        class: "form__submit"
      }, null, 8, ["text"]),
      (_ctx.currentRouteSignup != 'developer')
        ? (_openBlock(), _createBlock("div", _hoisted_1, [
            _createVNode("div", {
              class: "prefix",
              innerHTML: _ctx.mainText.alreadyHaveAccount.text
            }, null, 8, ["innerHTML"]),
            _createVNode("a", {
              href: "/login",
              class: "suffix",
              innerHTML: _ctx.mainText.alreadyHaveAccount.login
            }, null, 8, ["innerHTML"])
          ]))
        : _createCommentVNode("", true)
    ], 32)
  ], 64))
}