export const TEXT = {
  menu: {
    home: "Home",
    forDeveloper: "For Developer",
    forAgent: "For Agent",
    aboutus: "About us",
  },
  tel: "092-268-7075",
  address:
    "เลขที่ 25 อาคารอัลม่า ลิงค์ ชั้น 18 ห้อง 186<br/> ซอยชิดลม ถนนเพลินจิต แขวงลุมพินี เขตปทุมวัน<br/> กรุงเทพมหานคร",
  agentLogin: "Agent Login",
  signup: "Sign Up",
  developerSignupButton:"Request demo",
  addline: "Add Line @propfit<br class='hide-mobile' />CLICK!",
  addlinelink: "https://line.me/ti/p/~@propfit",
  trustedBy: "TRUSTED BY  :",
  trustedByDev: "TRUSTED BY DEVELOPERS :",
  baht: "บาท",
  sales_value: "4,167,000,000",
  sales_value_text: "มูลค่ายอดขายอสังหาฯของ Propfit :",
  sales_value_date: "ข้อมูล ณ วันที่ 4 มี.ค. 2567",
  learnmore: "Learn more",
  request_demo: "Request demo",
  signup_for_free: "Signup for free",
  benefits: "BENEFITS",
  agent: "Agent",
  developer: "Developer",
  alreadyHaveAccount:{
    text:'Already have an account?',
    login:'Login'
  },
  home: {
    banner: {
      titlehighlight: "Sale Driven",
      title: "Property <br/>Agent network",
      detail:
        "“ตัวกลาง” ระหว่างเครือข่ายของ “นายหน้าอสังหาริมทรัพย์” และ “ผู้พัฒนาอสังหาริมทรัพย์” ให้สามารถทำงานร่วมกัน เพื่อปิดการขายโครงการอสังหาฯ อย่างมีประสิทธิภาพ”",
      btnDevelper: "สำหรับ Developer",
      btnAgent: "สำหรับ Agent",
    },
    developer: {
      madeForDev: "Made for developer",
      title:
        "การันตียอดขายให้ผู้พัฒนา อสังหาริมทรัพย์ ในรูปแบบ Pay Per Performance",
      co_broking: "Co-Agent",
      co_broking_detail:
        "บริการ Stock Listing ทั้งแพลตฟอร์ม B2B และ B2C เพื่อให้นายหน้าฯในระบบช่วยขายและขยายฐาน ลูกค้า",
      marketing: "Marketing",
      marketing_detail:
        "บริการให้คำปรึกษาด้านการตลาดฟรี และเป็น เจ้าเดียวที่การันตียอดขายด้วยวิธี Co-investment ",
      sales_management: "Sales management",
      sales_management_detail:
        "บริหารการขายตั้งแต่ต้นจนจบ แบบ One-Stop Service เพื่อลดค่าใช้จ่ายให้ผู้พัฒนาฯ",
    },
    agent: {
      madeForAgent: "Made for agent",
      title_highlight: "“ดีลครบ จบง่าย ได้มากกว่า”",
      title: "Propfit ติดอาวุธเพิ่มยอดขายให้นายหน้าอสังหาฯ",
      stock_title: "Stock inventory",
      stock_detail: "ศุนย์รวมอสังหาฯกว่า 8,000 รายการ",
      commission_title: "Commission",
      commission_detail: "Commission สูงสุดถึง 5%",
      marketing_title: "Marketing",
      marketing_detail: "ช่วยหาลูกค้าให้",
      crm_title: "CRM",
      crm_detail: "ระบบช่วยบริหารจัดการและปิดการขายได้อย่างมีประสิทธิภาพ",
    },
    aboutus: {
      title: "About us",
      detail: "More information about vision & Team member",
      btn: "About Propfit",
    },
  },
  about: {
    banner: {
      title: "Propfit’s Vision",
      detail:
        "เรามองว่าโมเด็ลการทำการตลาดและการขายต้องเปลี่ยนไป และเราสนใจตลาดอสังหาฯ ของไทย ซึ่งมีนายหน้าฟรีแลนซ์เก่งๆเยอะมาก จึงเป็นที่มาของ Propfit แพลตฟอร์ม ดิจิตัลของตลาดอสังหาริมทรัพย์ที่ช่วยให้ “นายหน้าอสังหาริมทรัพย์” และ “ผู้พัฒนาอสังหาริมทรัพย์” ทำงานร่วมกันเพื่อปิดการขายโครงการอสังหาฯอย่างมีประสิทธิภาพ",
    },
    core_team: {
      title: "Core teams",
      founderTeam: "Founder team",
      marketingTeam: "Marketing team",
      businessDevelopment: "Business Development",
      operationTeam: "Operation team",
      technologyTeam: "Technology team",
    },
  },
  requestdemo: {
    banner: {
      title: "ให้ Propfit ช่วยขาย <br/>การันตียอดได้",
      detail:
        "เครือข่ายนายหน้าอสังหา เจ้าแรกและเจ้าเดียว <br/>ที่กล้าการันตียอดขายให้กับผู้พัฒนาอสังหาฯ",
    },
    form: {
      title: "กรอกข้อมูล <br/>เพื่อรับรายละเอียดเพิ่มเติม",
    },
    service: {
      title: "บริการเพิ่มยอดขายให้ผู้พัฒนาฯ <br/>ที่ครบวงจรที่สุด",
      stock_title:
        "บริการ Stock Listing เพิ่มโอกาสการขายทั้งในกลุ่ม นายหน้าฯและลูกค้า",
      stock_detail:
        "นายหน้าอสังหาฯ ในระบบกว่า 1,000 คน สามารถ ช่วยขายผ่าน B2B Stock Listing Platform ของ Propfit และเรายังขยายฐานลูกค้าให้ด้วยเว็บไซต์ รวมโปรโมชั่นแบบ B2C",
      marketing_title: "การันตียอดขายด้วย Marketing Co-investment",
      marketing_detail:
        "Propfit มีบริการทำการตลาดโดยทีมงานมืออาชีพ อ้างอิงจากฐานลูกค้าที่สนใจซื้ออสังหาฯ ในหลากหลายทำเล และเป็นเจ้าเดียวที่พร้อมการันตี ยอดขายให้กับผู้พัฒนาฯ ในรูปแบบ Money Back Guarantee",
      management_title: "บริการ Sales Management ครบวงจร",
      management_detail:
        "Propfit มีทีมดูแลการขายมืออาชีพ แบบ One-Stop Service โดยเน้นให้ผู้พัฒนาฯ สร้างยอดขายได้ มากขึ้น แต่ใช้ต้นทุนน้อยลง",
    },
    comments: {
      title: "ความคิดเห็นจากผู้พัฒนาฯที่ร่วมงานกับเรา",
      subtitle:
        "Propfit ได้รับความไว้วางใจจากบริษัทผู้พัฒนาอสังหาริมทรัพย์ชั้นนำให้ช่วยเพิ่มยอดขายด้วย เครือข่ายนายหน้าอสังหาฯของเรา",
      sansiri: "Propfit ช่วยเราเพิ่มยอดขาย <br/>กว่าไตรมาสละ 200 ล้านบาท",
      ap: "Propfit ประสานงานง่าย <br/>ขายให้เราได้เป็นร้อยล้านแล้ว",
      a5:
        "การจัดการ Sales Management <br/>ของ Propfit ครบวงจรจริง <br/>ทีมมืออาชีพมาก",
    },
  },
  form: {
    fullName: "Full name",
    company: "Company",
    telphone: "Telephone",
    email: "Email",
    password: "Password",
  },
  customers: {
    sansiri: "แสนสิริ",
    ap: "AP",
    a5: "Asset Five",
  },
  signuppage: {
    developer: {
      title: "ให้ Propfit",
      titlehighlight: "ช่วยเพิ่มยอดขาย <br/>โครงการของคุณ",
      detail:
        "ลงทะเบียน ปรึกษา Propfit ด้านการตลาด และ การขายโครงการอสังหาฯ เพื่อเพิ่มยอดขายให้โครงการ",
      benefits: [
        "ให้คำปรึกษาด้านการตลาดฟรี",
        "มี Agent ในระบบกว่า 1000 คน",
        "การันตียอดขายแบบ Pay Per Performance",
      ],
      form_title: "กรอกข้อมูลเพื่อ<br class='hide-md'/>รับรายละเอียดเพิ่มเติม",
    },
    agent: {
      title: "Co-Agent กับ Propfit",
      titlehighlight: "ขายง่าย <br class='hide-mobile'/>Commission สูง",
      detail:
        "สมัคร Propfit เพื่อรับสิทธิ์เข้าถึง Stock กว่า 8,000 ยูนิต และบริการช่วยเพิ่มยอดขาย ฟรี! <br/>ไม่เสียค่าใช้จ่าย",
      benefits: [
        "เข้าถึง Stock มือหนึ่งกว่า 100 โครงการ",
        "ระบบ CRM ช่วยบริหารจัดการลูกค้า",
        "รับ Commission สูงสุดถึง 5%",
      ],
      form_title: "ลงทะเบียนเพื่อเข้าใช้ระบบ Propfit",
    },
  },
  select_for_signup: {
    title: "คุณต้องการสมัครสมาชิกในรูปแบบใด",
  },
  register: "สมัครสมาชิก",
  developerRegister: "Request Demo",
  join_with_us: "ร่วมงานกับ Propfit",
  singup_success:
    "ทีมงาน Propfit ได้รับข้อมูลของท่านแล้ว<br/> เราจะโทรติดต่อท่านกลับโดยเร็ว",
  back_home: "Back to home",
};
