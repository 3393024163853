
import { Options, Vue } from "vue-class-component";
import "../assets/scss/request_demo.scss";
import TrustedBy from "../components/TrustedBy.vue";
import SalesValue from "../components/SalesValue.vue";
import CustomInput from "../components/CustomInput.vue";
import Button from "../components/Button.vue";
import Card from "../components/Card.vue";
import CardComment from "../components/CardComment.vue";
import Form from "../components/Form.vue";
import { TEXT } from "../text";

@Options({
  name: "RequestDemo",
  components: {
    TrustedBy,
    SalesValue,
    CustomInput,
    Button,
    Card,
    CardComment,
    Form,
  },
  data() {
    return {
      mainText: TEXT,
      text: TEXT.requestdemo,
      formText: TEXT.form,
    };
  },
})
export default class RequestDemo extends Vue {
  mainText?: any;
  text?: any;
  formText?: any;
}
