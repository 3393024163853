import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import RequestDemo from "../views/RequestDemo.vue";
import Signup from "../views/Signup.vue";
import SelectForSignup from "../views/SelectForSignup.vue";
import SignupSuccess from "../views/SignupSuccess.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/requestdemo",
    name: "Request Demo",
    component: RequestDemo,
  },
  {
    path: "/signup",
    name: "Sign Up",
    component: Signup,
  },
  {
    path: "/select-for-signup",
    name: "Select For Signup",
    component: SelectForSignup,
  },
  {
    path: "/signupsuccess",
    name: "Sign Up Success",
    component: SignupSuccess,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
