import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "signup_success" }
const _hoisted_2 = { class: "success" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", {
        class: "success__message",
        innerHTML: _ctx.text.singup_success
      }, null, 8, ["innerHTML"]),
      _createVNode(_component_Button, {
        text: _ctx.text.back_home,
        class: "form__submit",
        to: "/",
        btnType: "opacity"
      }, null, 8, ["text"])
    ])
  ]))
}