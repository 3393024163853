
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    logo: String,
    text: String,
    name: String,
  },
})
export default class CardComment extends Vue {
  logo?: string;
  text?: string;
  name?: string;
}
