
import { Options, Vue } from "vue-class-component";
import "../assets/scss/about.scss";
import CardMini from "../components/CardMini.vue";
import { TEXT } from "../text";

@Options({
  name: "about",
  components: { CardMini },
  data() {
    return {
      text: TEXT.about,
    };
  },
})
export default class About extends Vue {
  text?: any;
}
